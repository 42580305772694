import {mapActions, mapGetters, mapMutations} from "vuex";
import AnimatedNumber from "animated-number-vue";
import recipe from "@/modules/one-recipe/recipe/index.vue";

export default {
  name: "receipt",
  components: {
    AnimatedNumber,
    recipe
  },
  data() {
    return {
      personCount: '',
      cokingTime: '',
      duration: 4000
    }
  },
  watch: {
    '$route': function () {
      this.getReceipt({slug: this.$route.params.slug}).then(() => {
        this.personCount = parseInt(this.oneRecipe.numberOfPersons)
        this.cokingTime = parseInt(this.oneRecipe.cokingTime)
      })
    }
  },
  created() {
    this.getReceipt({slug: this.$route.params.slug}).then(() => {
      this.personCount = parseInt(this.oneRecipe.numberOfPersons)
      this.cokingTime = parseInt(this.oneRecipe.cokingTime)
    })
  },
  computed: {
    ...mapGetters({
      oneRecipe: 'receipt/oneRecipe'
    })
  },

  methods: {
    ...mapMutations({}),
    ...mapActions({
      getReceipt: 'receipt/GET_ONE_RECEIPT'
    }),

    formatToPersons(personCount) {
      return personCount
    },
    formatToCoking(cokingTime) {
      return cokingTime
    }
  }
}
